import React from "react";
import Layout from "./src/components/Layout";
import { RatingsProvider } from "./src/components/RatingsContext";

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  return <RatingsProvider>{element}</RatingsProvider>;
}
