import React, { useState } from "react";

// Create an ratings context.

const RatingsContext = React.createContext();

export function RatingsProvider({ children }) {
  // We need to stick state in here.
  const [ratings, setRatings] = useState([]);
  return (
    <RatingsContext.Provider value={[ratings, setRatings]}>
      {children}
    </RatingsContext.Provider>
  );
}

export default RatingsContext;
